import React from "react";

import { Layout, SEO } from "../../../../components/structure";
import {
  Button,
  Content,
  Hero,
  HospitalityPremiumUpgrade,
  CallToAction,
  HospitalitySplitBlock,
  HospitalityGrid,
} from "../../../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const HospitalityStayPlusPage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "GettyImages-1447649580.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      airIcon: file(base: { eq: "AirplaneIcon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
          fixed(width: 45, height: 80, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      stayPlusIcon: file(
        base: { eq: "Icons_Product_hospitality_final_MyStay_copy.png" }
      ) {
        childImageSharp {
          fixed(width: 51, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lcrProductImage: file(base: { eq: "LCR-Device-SWA.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      loyaltyIcon: file(base: { eq: "handPlus.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revPlatformBackground: file(
        base: { eq: "IndustryCruiseRevenuePlatform.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airTestimonials: file(base: { eq: "IndustryAirTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airCanada: file(base: { eq: "AC_Inline.png" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }
      sp: file(base: { eq: "55.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      whiteLabelIcon: file(base: { eq: "55.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastestTimeIcon: file(base: { eq: "79.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      riskFreeIcon: file(base: { eq: "163.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      dataDrivenIcon: file(base: { eq: "41.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastIntegrationIcon: file(base: { eq: "93.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      provenRevenueIcon: file(base: { eq: "101.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      spaIcon: file(base: { eq: "blue_icon_1.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      productOverview: file(
        base: { eq: "ALC-BFU-Arena_0.1-Tablet-Mockup.png" }
      ) {
        childImageSharp {
          fixed(width: 400, height: 510, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      continentalIcon: file(base: { eq: "blue_icon_2.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fitnessIcon: file(base: { eq: "blue_icon_3.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tourIcon: file(base: { eq: "blue_icon_4.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      amenityIcon: file(base: { eq: "blue_icon_5.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      diningIcon: file(base: { eq: "blue_icon_6.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      liveIcon: file(base: { eq: "blue_icon_7.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      carIcon: file(base: { eq: "blue_icon_8.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      meetingIcon: file(base: { eq: "blue_icon_9.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      muchMoreIcon: file(base: { eq: "blue_icon_10.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
          fixed(width: 58, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrowTriColor: file(
        base: { eq: "HorizontalWebArrowTriColour.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const ProductsHeroContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    > div {
      flex-basis: 48%;
    }

    .graphic-con {
      width: 100%;
      max-width: 700px;
      height: 300px;

      @media only screen and ${mq.maxMd} {
        align-self: flex-start;
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .title-con {
      h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_orange};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
      }
    }
  `;

  const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 80px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }

    .icon-con {
      .gatsby-image-wrapper {
        filter: brightness(0) saturate(100%) invert(32%) sepia(100%)
          saturate(1931%) hue-rotate(210deg) brightness(89%) contrast(111%);
      }
    }

    .platform-headline {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;

      /*
       .headline-con {
         margin-bottom:0;
       }
       */
    }
    ${props =>
      props.lightGreyBg &&
      css`
        background-color: ${brand.colors.pg_primary_light};
      `}
  `;

  const HospitalityGridContainer = styled.div`
    background-color: ${brand.colors.pg_blue};
    width: 100%;
    /*max-width: 1400px;*
  /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    /*display: flex;*/
    align-items: center;
    justify-content: space-between;
    gap: 2%;
    grid-row-gap: 20px;
    display: grid;
    /*grid-gap: 1rem;*/
    grid-template-columns: repeat(5, 1fr);
    padding: 2em;
    padding-bottom: 80px;

    @media only screen and ${mq.maxMd} {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      padding: 2em;
    }

    .headline-con {
      p {
        color: #080a12 !important;
      }
    }

    div {
      color: black;
    }
  `;
  return (
    <Layout>
      <SEO
        title="StayPlus | Plusgrade"
        description={
          "Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight."
        }
        keywords={"airline, flight, upgrades"}
        lang="fr"
      />
      <Content whiteBG>
        <Hero
          title={"StayPlus"}
          image={pageQuery["hero"]}
          subtitle={
            "Offrez à vos clients des offres personnalisées afin de leur permettre d’accéder à davantage de services, de produits et d’expériences."
          }
          buttonURL={"/fr/industries/hotellerie/demonstration/"}
          buttonLabel={"Pour démarrer"}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
          fullBG
        />

        <HospitalityPremiumUpgrade
          title={"Aperçu des produits"}
          copy={
            "Permettez à vos clients d’accéder à davantage de services, de produits et d’expériences grâce à des offres personnalisées. <strong>MyStay</strong> permet d’offrir une expérience ultime aux clients, en plus d’un potentiel de revenus auxiliaires inégalé."
          }
          buttonDest={"/fr/contact/demandez-une-demonstration/"}
          image={pageQuery["productOverview"]}
          buttonLabel={"Demander une démonstration"}
          icon={pageQuery["stayPlusIcon"]}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
        ></HospitalityPremiumUpgrade>

        <CallToAction
          primaryBlueBg
          title={"Améliorez l’expérience de vos clients"}
          subtitle={
            "Permettez à vos clients de personnaliser leur séjour en fonction de leurs besoins, ce qui augmente la satisfaction des clients et l’évaluation de l’hôtel."
          }
          style={{ paddingBottom: "0px" }}
        ></CallToAction>

        <HospitalityGridContainer>
          <HospitalityGrid
            title={"Rendez-vous dans un spa"}
            icon={pageQuery["spaIcon"]}
          />
          <HospitalityGrid
            title={"Déjeuner continental"}
            icon={pageQuery["continentalIcon"]}
          />
          <HospitalityGrid
            title={"Cours de conditionnement physique"}
            icon={pageQuery["fitnessIcon"]}
          />
          <HospitalityGrid
            title={"Excursions touristiques"}
            icon={pageQuery["tourIcon"]}
          />
          <HospitalityGrid
            title={"Location d’équipements"}
            icon={pageQuery["amenityIcon"]}
          />
          <HospitalityGrid
            title={"Réservations de soupers"}
            icon={pageQuery["diningIcon"]}
          />
          <HospitalityGrid
            title={"Spectacles en direct"}
            icon={pageQuery["liveIcon"]}
          />
          <HospitalityGrid
            title={"Location de voitures/transport"}
            icon={pageQuery["carIcon"]}
          />
          <HospitalityGrid
            title={"Espaces de rencontre"}
            icon={pageQuery["meetingIcon"]}
          />
          <HospitalityGrid
            title={"Et bien plus!"}
            icon={pageQuery["muchMoreIcon"]}
          />
        </HospitalityGridContainer>

        <PlatformDetailContainer lightGreyBg>
          <div className="inner-icon-container">
            <IconContainer
              darkOnWhite
              icon={pageQuery["whiteLabelIcon"]}
              headline={"Solutions en marque blanche"}
              copy={
                "Une solution adaptée aux hôtels qui offrent une expérience client fluide et sans faille."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["fastestTimeIcon"]}
              headline={"Délai le plus rapide de mise sur le marché"}
              copy={
                "Commencez à générer des revenus supplémentaires en 3 semaines."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["riskFreeIcon"]}
              headline={"Investissement sans risque"}
              copy={"Aucun frais de mise en œuvre ni coûts initiaux."}
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["dataDrivenIcon"]}
              headline={"Prise de décision fondée sur des données"}
              copy={
                "Règles et leviers commerciaux robustes conçus pour optimiser votre programme de surclassement."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["fastIntegrationIcon"]}
              headline={"Intégrations rapides"}
              copy={
                "Mise en œuvre facile avec vos systèmes PMS et de gestion des canaux existants."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["provenRevenueIcon"]}
              headline={"Croissance prouvée des revenus"}
              copy={
                "Maximiser les bénéfices grâce à des produits et des flux de revenus diversifiés..."
              }
            />
          </div>
        </PlatformDetailContainer>

        <Content noMargin>
          <ProductsHeroContainer>
            <div className="graphic-con">
              <GatsbyImage
                fluid={
                  pageQuery["HeroRightArrowTriColor"].childImageSharp.fluid
                }
                imgStyle={{ objectPosition: "right center" }}
              />
            </div>
            <div className="title-con">
              <h2>{"Intégration sans\neffort"}</h2>
            </div>
          </ProductsHeroContainer>
        </Content>

        <Content orangeBG>
          <HospitalitySplitBlock
            copy={
              "Transformez vos opérations hôtelières grâce à notre logiciel de vente incitative. Conçue pour une intégration transparente avec votre marque actuelle et vos systèmes de gestion immobilière, notre solution automatise la vente incitative afin d’augmenter votre potentiel de revenus tout en améliorant l’expérience de vos clients."
            }
            buttonLabel={"Voir toutes les intégrations"}
            buttonIcon={pageQuery["rightArrowIconWhite"]}
            graphicStyle={{ objectPosition: "center top" }}
            image={pageQuery["speedPassProduct"]}
            id="speed-pass2"
            buttonDestination={"/fr/industries/hotellerie/integrations"}
            style={{ marginTop: "-60px" }}
            productPage
          />
        </Content>

        <CallToAction
          title={
            "Associez-vous à nous dès aujourd’hui et optez pour un avenir de l’hôtellerie tourné vers l’excellence."
          }
          subtitle={"Contactez-nous pour en savoir plus."}
        >
          <Button
            destination={"/fr/contact/demandez-une-demonstration/"}
            label={"Demander une démonstration"}
            icon={pageQuery["rightArrowIcon"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default HospitalityStayPlusPage;

const IconCon = styled.div`

${font.imports.sherika}
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }

  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props =>
    props.whiteOrange &&
    css`
      flex-basis: 30%;
      align-items: flex-start;
      flex-wrap: wrap;

      .headline-con {
        text-align: left;
        h4 {
          color: ${brand.colors.pg_primary_dark} !important;
          font-family: ${font.family.sherika};
          font-size: 22px !important;
          font-weight: 700;
          white-space: pre-line;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
        p {
          color: ${brand.colors.pg_primary_dark};
          font-family: ${font.family.sherika};
          font-weight: 400;
          margin: 20px 0;
          min-height: 96px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        .headline {
          min-height: 60px;
          display: flex;
          align-items: center;
          @media only screen and ${mq.maxMd} {
            text-align: center;
            justify-content: center;
          }
        }
      }
    `}

  ${props =>
    props.whiteOnBlue &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #fff !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    `}

  ${props =>
    props.darkOnWhite &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #080a12 !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
        color: #080a12 !important;
      }
    `}
`;

const IconContainer = ({ icon, highlight, headline, copy, ...props }) => {
  const ic = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : <></>;
  return (
    <IconCon {...props}>
      <div className="icon-con">{ic}</div>
      <div className="headline-con">
        <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">{headline}</h4>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </div>
    </IconCon>
  );
};

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
